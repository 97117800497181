import React, { useState } from 'react';
import logo from '../images/letz_logo.png';
// import letzLogoNewYear from '../images/letz_logo_new_year.png';
import {
  Box,
  Button,
  AppBar,
  Divider,
  Drawer,
  IconButton,
  // ListItem,
  createTheme,
  ThemeProvider,
  Typography,
  List,
  Toolbar,
  MenuItem,
  Menu
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import MenuIcon from '@mui/icons-material/Menu';
import '../styles/App.css';
import { useTranslation } from 'react-i18next';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
//import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
//import LanguageIcon from '../Icons/language_icon';
import LanguageIcon from '@mui/icons-material/Language';
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi';
import { saveLanguage } from '../lib/language';
import { getLanguage } from '../lib/language';

const theme = createTheme({
  palette: {
    primary: {
      main: '#111'
    }
  }
});

const drawerWidth = '100%';
// const navItems = ['driver', 'contact'];
const driverItems = ['instruction', 'download', 'join'];
const driverPages = ['driver/instruction', 'driver', 'become_a_driver'];
const lngs = ['en', 'ro', 'ru', 'uk'];
const lngsS = ['en-US', 'ro-RO', 'ru-RU', 'uk-UA'];
const browserlang = window.navigator.language

function Header(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [driverOpen, setDriverOpen] = useState(false);
  const [languageOpen, setLanguageOpen] = useState(false);
  const { t } = useTranslation(['header', 'common']);
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(browserlang);

  switch (language) {
    case "en":
      break;
    case "en-US":
      break;
    case "ro":
      break;
    case "ro-RO":
      break;
    case "ru":
      break;
    case "ru-RU":
      break;
    case "uk":
      break;
    case "uk-UA":
      break;
    default:
      setLanguage('en');
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
    if (languageOpen !== false) {
      setLanguageOpen(false)
    }
    if (driverOpen !== false) {
      setDriverOpen(false)
    }
  };

  // const languageDrawerToggle = () => {
  //   setLanguageOpen(!languageOpen);
  // };

  const selectLanguageMobile = () => {
    languageOpen ? setLanguageOpen(false) : setLanguageOpen(true)
    if(driverOpen === true) {
      setDriverOpen(false) 
    }
  };

  const driverDrawerToggle = () => {
    driverOpen ? setDriverOpen(false) : setDriverOpen(true)
    if(languageOpen === true) {
      setLanguageOpen(false)
    }
  };

  const drawerToolBar = (<>
    <Box
      // onClick={handleDrawerToggle}
      sx={{
        transition: '0.5s',
        backgroundColor: '#111',
        color: '#fff',
        pt: 2,
        textAlign: 'center',        
        height: languageOpen ? (languageOpen ? '305px' : '150px') : (driverOpen ? '255px' : '145px')
      }}>
      <NavLink to='/' onClick={handleDrawerToggle}>
        <img src={logo} alt='Logo' height={25} width={100} />
        {/* <img src={letzLogoNewYear} alt='Logo' height={37} width={100} /> */}
        {/* <PinNeaYear/> */}
      </NavLink>
      <Divider id='handleDrawerToggle' />
      {/* <List>         
             {navItems.map((item) => (  
               <NavLink key={item} style={{color: '#fff', textDecoration: 'none'}} to={`/${item}`}>     
                 <ListItem  sx={{ justifyContent: 'center', userSelect: 'none'}}>                             
                  <Typography                                  
                    className='headButtons'
                    
                    sx={{
                        fontFamily: 'Montserrat', 
                        fontSize: 16, 
                        fontWeight: 600
                        }} 
                  >  {t(item)}            
                  </Typography>                                                              
               </ListItem> 
              </NavLink>               
             ))} 
             </List>  */}
      {/* <Button  
                className='headButtons'                             
                color='primary'	                            											   
                sx={{ 
                    mt: 2, // will be delated
                    fontFamily: 'Montserrat', 
                    fontSize: 16, 
                    fontWeight: 600,
                    mx: '0.8vw',												    		
                    color: '#fff',
                    outline: 'none',
                    }}
              > <Link  href={sofer} download sx={{transition: '200ms', textDecoration: 'none', color: 'white', '&:hover': {color:'#797af0'}}}> {t('download')} </Link>                
              </Button>         */}
      <List>
        <Typography
          onClick={driverDrawerToggle}
          className='headButtons'
          sx={{
            mt: 3, // will be delated
            fontFamily: 'Montserrat',
            fontSize: 16,
            fontWeight: 600
          }}
        >
          <LocalTaxiIcon sx={{ my: '-6px', mr: 1 }} />
          {t('driver')}
        </Typography>
      </List>
      {/* <Box
        onClick={driverDrawerToggle}
        sx={{
          backgroundColor: '#111',
          color: '#fff',
          textAlign: 'center',
        }}> */}
          {/* <Divider id='driverDrawerToggle' /> */}
          <Box sx={{transition: '0.5s', height: driverOpen ? '110px' : '0px', overflowY: 'hidden' }}>
          {driverPages.map((item, i) => (
            <NavLink key={item} style={{ color: '#fff', textDecoration: 'none' }} to={`/${item}`}>

                <Typography
                onClick={() => {driverDrawerToggle(); handleDrawerToggle()}}
                key={item}
                  className='headButtons'
                  sx={{
                    color: '#fff',
                    fontFamily: 'Montserrat',
                    fontSize: 16,
                    fontWeight: 600,
                    justifyContent: 'center', 
                    userSelect: 'none',
                    mt: 1.3,
                    mb: 1
                  }}
                > {t(driverItems[i])}
                </Typography>
            </NavLink>
          ))}
      </Box>
      <Box
        // onClick={selectLanguageMobile}
        sx={{
          backgroundColor: '#111',
          color: '#fff',
          textAlign: 'center',
          pt: 1
        }}>
        <Typography
          onClick={selectLanguageMobile}
          className='headButtons'
          sx={{
            mb: 0, // will be delated
            fontFamily: 'Montserrat',
            textTransform: 'uppercase',
            fontSize: 16,
            fontWeight: 600
          }}
        >
          <LanguageIcon className='rotate45' sx={{ my: '-6px', mr: 1 }} />
          {t('language')}
        </Typography>
        {/* <Divider id='languageDrawerToggle' /> */}
        <Box sx={{transition: '0.5s', height: languageOpen ? '160px' : '15px', overflowY: 'hidden'}}>
          {lngs.map((lng, i) => (
            // <ListItem
            //   key={lng}
            //   onClick={() => { i18n.changeLanguage(lng); setLanguage(lng); handleDrawerToggle()}}
            //   sx={{ justifyContent: 'center', userSelect: 'none' }}
            // >
            <Typography
              key={lng}
              onClick={() => { i18n.changeLanguage(lng); setLanguage(lng); handleDrawerToggle() }}
              className='headButtons'
              sx={{
                // display: 'none',
                color: '#fff',
                // (language === lng || language === lngsS[i]) ? '#797af0' : 
                userSelect: 'none',
                fontFamily: 'Montserrat',
                fontSize: 16,
                fontWeight: 600,
                mt: 2,
                mb: lngs.length === i + 1 ? 2 : 0
              }}
            > {t(lng)}
            </Typography>
            // </ListItem>
          ))}
        </Box>
      </Box>
    </Box>
  </>);

  // const drawerLanguage = (
  //   <Box
  //     // onClick={languageDrawerToggle}
  //     sx={{
  //       backgroundColor: '#111',
  //       color: '#fff',
  //       textAlign: 'center',
  //     }}>
  //     <List>
  //       <Divider id='languageDrawerToggle' />
  //       {lngs.map((lng, i) => (
  //         <ListItem
  //           key={lng}
  //           onClick={() => { i18n.changeLanguage(lng); setLanguage(lng) }}
  //           sx={{ justifyContent: 'center', userSelect: 'none' }}
  //         >
  //           <Typography
  //             className='headButtons'
  //             sx={{
  //               color: (language === lng || language === lngsS[i]) ? '#797af0' : '#fff',
  //               fontFamily: 'Montserrat',
  //               fontSize: 16,
  //               fontWeight: 600
  //             }}
  //           > {t(lng)}
  //           </Typography>
  //         </ListItem>
  //       ))}
  //     </List>
  //   </Box>
  // );

  // const drawerDriver = (
  //   <Box
  //     onClick={driverDrawerToggle}
  //     sx={{
  //       backgroundColor: '#111',
  //       color: '#fff',
  //       textAlign: 'center',
  //     }}>
  //     <List>
  //       <Divider id='driverDrawerToggle' />
  //       {driverPages.map((item, i) => (
  //         <NavLink key={item} style={{ color: '#fff', textDecoration: 'none' }} to={`/${item}`}>
  //           <ListItem
  //             key={item}
  //             // onClick={() => { i18n.changeLanguage(lng); setLanguage(lng) }}
  //             sx={{ justifyContent: 'center', userSelect: 'none' }}
  //           >
  //             <Typography
  //               className='headButtons'
  //               sx={{
  //                 color: '#fff',
  //                 fontFamily: 'Montserrat',
  //                 fontSize: 16,
  //                 fontWeight: 600
  //               }}
  //             > {t(driverItems[i])}
  //             </Typography>
  //           </ListItem>
  //         </NavLink>
  //       ))}
  //     </List>
  //   </Box>
  // );

  const container = window !== undefined ? () => window().document.body : undefined;
  // const container2 = window !== undefined ? () => window().document.body : undefined;
  // const container3 = window !== undefined ? () => window().document.body : undefined;

  return (<>
    <Box sx={{ display: 'flex' }}>
      <ThemeProvider theme={theme}>
        <AppBar component='nav' sx={{ height: '100px' }} >
          <Box 
          // id="snow-container"
          sx={{
            height: '100px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: { xs: 'space-between' }
          }}>
            {/* <div className="snow" style={{top: '-33%', left: '94%', fontSize: '22px', animationDuration: '3s', userSelect: 'none'}}>❆</div>
            <div className="snow" style={{top: '-23%', left: '63%', fontSize: '24px', animationDuration: '3.1s', userSelect: 'none'}}>❅</div>
            <div className="snow" style={{top: '-26%', left: '33%', fontSize: '18px', animationDuration: '3.2s', userSelect: 'none'}}>❆</div>
            <div className="snow" style={{top: '-28%', left: '83%', fontSize: '20px', animationDuration: '3.3s', userSelect: 'none'}}>❆</div>
            <div className="snow" style={{top: '-36%', left: '73%', fontSize: '21px', animationDuration: '3.4s', userSelect: 'none'}}>❅</div>
            <div className="snow" style={{top: '-16%', left: '23%', fontSize: '19px', animationDuration: '3.5s', userSelect: 'none'}}>❆</div>
            <div className="snow" style={{top: '-27%', left: '81%', fontSize: '22px', animationDuration: '3.6s', userSelect: 'none'}}>❆</div>
            <div className="snow" style={{top: '-23%', left: '13%', fontSize: '24px', animationDuration: '3.7s', userSelect: 'none'}}>❅</div>
            <div className="snow" style={{top: '-21%', left: '3%', fontSize: '18px', animationDuration: '3.8s', userSelect: 'none'}}>❆</div>
            <div className="snow" style={{top: '-22%', left: '0%', fontSize: '20px', animationDuration: '3.9s', userSelect: 'none'}}>❆</div>
            <div className="snow" style={{top: '-14%', left: '7%', fontSize: '21px', animationDuration: '4s', userSelect: 'none'}}>❅</div>
            <div className="snow" style={{top: '-6%', left: '17%', fontSize: '19px', animationDuration: '4.1s', userSelect: 'none'}}>❆</div>

            <div className="snow" style={{top: '-37%', left: '90%', fontSize: '22px', animationDuration: '4.2s', userSelect: 'none'}}>❆</div>
            <div className="snow" style={{top: '-24%', left: '60%', fontSize: '24px', animationDuration: '4.3s', userSelect: 'none'}}>❅</div>
            <div className="snow" style={{top: '-19%', left: '30%', fontSize: '18px', animationDuration: '4.4s', userSelect: 'none'}}>❆</div>
            <div className="snow" style={{top: '-29%', left: '85%', fontSize: '20px', animationDuration: '4.5s', userSelect: 'none'}}>❆</div>
            <div className="snow" style={{top: '-38%', left: '70%', fontSize: '21px', animationDuration: '4.6s', userSelect: 'none'}}>❅</div>
            <div className="snow" style={{top: '-17%', left: '20%', fontSize: '19px', animationDuration: '4.7s', userSelect: 'none'}}>❆</div>
            <div className="snow" style={{top: '-18%', left: '87%', fontSize: '22px', animationDuration: '4.8s', userSelect: 'none'}}>❆</div>
            <div className="snow" style={{top: '-13%', left: '10%', fontSize: '24px', animationDuration: '4.9s', userSelect: 'none'}}>❅</div>
            <div className="snow" style={{top: '-12%', left: '5%', fontSize: '18px', animationDuration: '5s', userSelect: 'none'}}>❆</div>
            <div className="snow" style={{top: '-31%', left: '89%', fontSize: '20px', animationDuration: '5.1s', userSelect: 'none'}}>❆</div>
            <div className="snow" style={{top: '-15%', left: '75%', fontSize: '21px', animationDuration: '5.2s', userSelect: 'none'}}>❅</div>
            <div className="snow" style={{top: '-10%', left: '12%', fontSize: '19px', animationDuration: '5.3s', userSelect: 'none'}}>❆</div>

            <div className="snow" style={{top: '-32%', left: '45%', fontSize: '22px', animationDuration: '6.7s', userSelect: 'none'}}>❆</div>
            <div className="snow" style={{top: '-11%', left: '50%', fontSize: '24px', animationDuration: '5.5s', userSelect: 'none'}}>❅</div>
            <div className="snow" style={{top: '-34%', left: '55%', fontSize: '18px', animationDuration: '7.6s', userSelect: 'none'}}>❆</div>
            <div className="snow" style={{top: '-39%', left: '47%', fontSize: '22px', animationDuration: '7.2s', userSelect: 'none'}}>❆</div>
            <div className="snow" style={{top: '-20%', left: '51%', fontSize: '24px', animationDuration: '6.8s', userSelect: 'none'}}>❅</div>
            <div className="snow" style={{top: '-40%', left: '53%', fontSize: '18px', animationDuration: '8.9s', userSelect: 'none'}}>❆</div>
            <div className="snow" style={{top: '-41%', left: '41%', fontSize: '22px', animationDuration: '7s', userSelect: 'none'}}>❆</div>
            <div className="snow" style={{top: '-35%', left: '43%', fontSize: '24px', animationDuration: '3.9s', userSelect: 'none'}}>❅</div>
            <div className="snow" style={{top: '-42%', left: '40%', fontSize: '18px', animationDuration: '3.6s', userSelect: 'none'}}>❆</div>
            <div className="snow" style={{top: '-25%', left: '100%', fontSize: '20px', animationDuration: '7.3s', userSelect: 'none'}}>❆</div>
            <div className="snow" style={{top: '-30%', left: '92%', fontSize: '21px', animationDuration: '3.7s', userSelect: 'none'}}>❅</div>
            <div className="snow" style={{top: '-43%', left: '97%', fontSize: '19px', animationDuration: '3.5s', userSelect: 'none'}}>❆</div> */}
            <NavLink to='/' style={{ marginLeft: '5vw' }}>              
              <img src={logo} alt='Logo' height={25} width={100} />
              {/* <img src={letzLogoNewYear} alt='Logo' height={37} width={100} /> */}
            </NavLink>
            <Toolbar sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton
                color='inherit'
                aria-label='open drawer'
                onClick={handleDrawerToggle}
                sx={{ mr: '5vw', display: { sm: 'none' } }}
              >
                <MenuIcon sx={{ mr: '-15px' }} />
              </IconButton>
              <Box sx={{ display: { xs: 'none', sm: 'flex' }, mr: '5vw', justifyContent: "flex-end" }}>
                {/* {navItems.map((item) => ( 
                          <NavLink key={item} style={{textDecoration: 'none'}} to={`/${item}`}>
                            <Button 
                              className='headButtons' 
                              color='primary'	                            											   
                              sx={{ 
                                  fontFamily: 'Montserrat', 
                                  fontSize: 16, 
                                  fontWeight: 600,
                                  mx: '0.8vw',												    		
                                  color: '#fff',
                                  outline: 'none',
                                  }}
                            >
                              {t(item)}
                            </Button> 
                          </NavLink>
                          ))
                        } */}
                {/* <Button  
                              className='headButtons'                             
                              color='primary'	                            											   
                              sx={{ 
                                  fontFamily: 'Montserrat', 
                                  fontSize: 16, 
                                  fontWeight: 600,
                                  mx: '0.8vw',												    		
                                  color: '#fff',
                                  outline: 'none',
                                  }}
                            > <Link  href={sofer} download sx={{transition: '200ms', textDecoration: 'none', color: 'white', '&:hover': {color:'#797af0'}}}> {t('download')} </Link>                              
                            </Button>  */}
                {/* <NavLink to='/driver/new' className='LetzDriver'>LD 1.0.0</NavLink>	    */}
                <PopupState variant='popover' popupId='demo-popup-menu' >
                  {(popupState) => (
                    <React.Fragment>
                      <Button
                        className='headButtons'
                        // id='EN'
                        sx={{
                          fontFamily: 'Montserrat',
                          fontSize: 16,
                          fontWeight: 600,
                          mx: '0.8vw',
                          width: '110px',
                          color: '#fff'
                        }}
                        {...bindTrigger(popupState)}>
                        {/* <LanguageIcon className='rotate45' sx={{ mr: '5px' }} />  */}
                        {t('driver')}
                      </Button>
                      <Menu sx={{ ml: '-15px', mt: '0px' }} {...bindMenu(popupState)}>
                        <Box
                          sx={{
                            backgroundColor: '#111',
                            mb: -1, mt: -2, py: 1, pr: 0,
                            fontFamily: 'Montserrat',
                            fontSize: 16,
                            fontWeight: 600,
                            px: 2,
                            width: 'auto',
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center" 
                          }}
                        >
                          {driverItems.map((item, i) => (
                            <NavLink
                              to={`/${driverPages[i]}`}
                              className='menuNavlinkButtons'
                              key={item}
                              onClick={popupState.close}
                            >
                              {t(item)}
                            </NavLink>
                          ))}
                        </Box>
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
                <PopupState variant='popover' popupId='demo-popup-menu' >
                  {(popupState) => (
                    <React.Fragment>
                      <Button
                        className='headButtons'
                        id='EN'
                        sx={{
                          fontFamily: 'Montserrat',
                          fontSize: 16,
                          fontWeight: 600,
                          mx: '0.8vw',
                          color: '#fff'
                        }}
                        {...bindTrigger(popupState)}>
                        <LanguageIcon className='rotate45' sx={{ mr: '5px' }} /> {t('language')}
                      </Button>
                      <Menu sx={{ ml: '-1.5vw', mt: '0px' }} {...bindMenu(popupState)}>
                        <Box
                          sx={{
                            backgroundColor: '#111',
                            mb: -1, mt: -2, py: 1, pr: 0,
                            fontFamily: 'Montserrat',
                            fontSize: 16,
                            fontWeight: 600,
                            width: 150
                          }}
                        >
                          {lngs.map((lng, i) => (
                            <MenuItem
                              className='headButtons'
                              sx={{
                                // color: (t('language') === 'ua' ? 'uk' : t('language')) === lngs[i] ? '#797af0' : '#fff',
                                color: (getLanguage() ? getLanguage() === lng : language === lng || language === lngsS[i]) ? '#797af0' : '#fff',
                                fontFamily: 'Montserrat',
                                fontSize: 16,
                                fontWeight: 600,
                                justifyContent: 'center',
                              }}
                              key={lng}
                              onClick={() => { i18n.changeLanguage(lng, popupState.close); saveLanguage(lng); setLanguage(lng) }}>
                              {t(lng)}
                            </MenuItem>
                          ))}
                        </Box>
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
              </Box>
            </Toolbar>
          </Box>
        </AppBar>
        <Box>
          <Drawer
            id='handleDrawerToggle'
            container={container}
            variant='temporary'
            anchor='top'
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{ keepMounted: true }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth},
            }}
          >
            {drawerToolBar}
          </Drawer>
        </Box>
        {/* <Box>
          <Drawer
            id='languageDrawerToggle'
            container={container}
            variant='temporary'
            anchor='top'
            open={languageOpen}
            onClose={languageDrawerToggle}
            ModalProps={{ keepMounted: true }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawerLanguage}
          </Drawer>
        </Box>
        <Box>
          <Drawer
            id='driverDrawerToggle'
            container={container}
            variant='temporary'
            anchor='top'
            open={driverOpen}
            onClose={driverDrawerToggle}
            ModalProps={{ keepMounted: true }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawerDriver}
          </Drawer>
        </Box> */}
      </ThemeProvider>
    </Box>
  </>)
}

Header.propTypes = {
  window: PropTypes.func,
};

export default Header


