import React from "react";
import { Box, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

function Footer() {
  const { t } = useTranslation("footer");

  return (
    <>
      <Box
        sx={{
          height: "80px",
          width: "100%",
          mt: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          background: "#000",
          fontSize: "14px",
          fontFamily: "Montserrat",
          fontWeight: 500,
          color: "#fff",
        }}
      >
        <Box sx={{ mt: "14px" }}>
          <Link
            href="https://www.facebook.com/people/Letz/100083091554230/"
            target="black"
            sx={{ color: "#fff", mr: 1, "&:hover": { cursor: "pointer" } }}
          >
            <FacebookIcon />
          </Link>
          <Link
            href="https://www.instagram.com/letz.moldova/?igshid=YWVjMmZiZjg%3D"
            target="black"
            sx={{ color: "#fff", "&:hover": { cursor: "pointer" } }}
          >
            <InstagramIcon />
          </Link>
        </Box>
        © Letz 2024, {t("all_rights_reserved")}
      </Box>
    </>
  );
}

export default Footer;
