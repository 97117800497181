import React from "react";
import { Typography, Box } from "@mui/material";
import instruction_1 from "../../../../images/instruction_1.png";
import instruction_2 from "../../../../images/instruction_2.png";
import instruction_3 from "../../../../images/instruction_3.png";
import instruction_4 from "../../../../images/instruction_4.png";

const title = (name, smUp, t) => {
  return (
    <Typography
      sx={{
        width: "95%",
        flexShrink: 0,
        fontFamily: "Montserrat",
        fontSize: smUp ? 20 : 16,
        fontWeight: 700,
      }}
    >
      {t(name)}
    </Typography>
  );
};

const subTitleTop = (name, smUp, t) => {
  return (
    <Typography
      sx={{
        fontFamily: "Montserrat",
        fontSize: smUp ? 17 : 14,
        fontWeight: 700,
      }}
    >
      {t(name)}
    </Typography>
  );
};

const subTitle = (name, smUp, t) => {
  return (
    <Typography
      sx={{
        mt: smUp ? 2 : 1,
        fontFamily: "Montserrat",
        fontSize: smUp ? 17 : 14,
        fontWeight: 700,
      }}
    >
      {t(name)}
    </Typography>
  );
};

const text = (text, smUp, t) => {
  return (
    <Typography
      sx={{
        mt: smUp ? 2 : 1,
        fontFamily: "Montserrat",
        fontSize: smUp ? 16 : 13,
        fontWeight: 500,
      }}
    >
      {t(text)}
    </Typography>
  );
};

const textTop = (text, smUp, t) => {
  return (
    <Typography
      sx={{
        fontFamily: "Montserrat",
        fontSize: smUp ? 16 : 13,
        fontWeight: 500,
      }}
    >
      {t(text)}
    </Typography>
  );
};

const content1_1 = (smUp, t, name) => {
  return (
    <>
      {textTop("text_1.1.1", smUp, t)}
      {text("text_1.1.2", smUp, t)}
      {text("text_1.1.3", smUp, t)}
      <Box sx={{ display: "flex", justifyContent: smUp ? "start" : "center" }}>
        {smUp ? (
          <img
            src={instruction_1}
            alt="motivation"
            style={{ marginTop: smUp ? "30px" : "10px" }}
          />
        ) : (
          <img
            src={instruction_1}
            alt="motivation"
            height={"70%"}
            width={"70%"}
            style={{ marginTop: smUp ? "30px" : "10px" }}
          />
        )}
      </Box>
      {text("text_1.1.4", smUp, t)}
    </>
  );
};

const content1_2 = (smUp, t, name) => {
  return (
    <>
      {subTitleTop("text_1.2.1", smUp, t)}
      {subTitle("text_1.2.2", smUp, t)}
      {text("text_1.2.3", smUp, t)}
      <ul
        style={{
          fontFamily: "Montserrat",
          fontSize: smUp ? 16 : 13,
          fontWeight: 500,
        }}
      >
        <li>{t("text_1.2.4")}</li>
        <li>{t("text_1.2.5")}</li>
        <li>{t("text_1.2.6")}</li>
      </ul>
      <Box sx={{ display: "flex", justifyContent: smUp ? "start" : "center" }}>
        {smUp ? (
          <img
            src={instruction_2}
            alt="motivation"
            height={923 / 1.3}
            width={461 / 1.3}
            style={{ marginTop: smUp ? "30px" : "10px" }}
          />
        ) : (
          <img
            src={instruction_2}
            alt="motivation"
            height={"70%"}
            width={"70%"}
            style={{ marginTop: smUp ? "30px" : "10px" }}
          />
        )}
      </Box>
      {text("text_1.2.7", smUp, t)}
    </>
  );
};

const content1_3 = (smUp, t, name) => {
  return (
    <>
      {textTop("text_1.3.1", smUp, t)}
      <Box sx={{ display: "flex", justifyContent: smUp ? "start" : "center" }}>
        {smUp ? (
          <img
            src={instruction_3}
            alt="motivation"
            height={956 / 1.5}
            width={521 / 1.5}
            style={{ marginTop: smUp ? "30px" : "10px" }}
          />
        ) : (
          <img
            src={instruction_3}
            alt="motivation"
            height={"70%"}
            width={"70%"}
            style={{ marginTop: smUp ? "30px" : "10px" }}
          />
        )}
      </Box>
      {text("text_1.3.2", smUp, t)}
      {text("text_1.3.3", smUp, t)}
      <Box sx={{ display: "flex", justifyContent: smUp ? "start" : "center" }}>
        {smUp ? (
          <img
            src={instruction_4}
            alt="motivation"
            style={{ marginTop: smUp ? "30px" : "10px" }}
          />
        ) : (
          <img
            src={instruction_4}
            alt="motivation"
            height={"70%"}
            width={"70%"}
            style={{ marginTop: smUp ? "30px" : "10px" }}
          />
        )}
      </Box>
    </>
  );
};

const content1_4 = (smUp, t, name) => {
  return <>{textTop("text_1.4.1", smUp, t)}</>;
};

const content1_5 = (smUp, t, name) => {
  return <>{textTop("text_1.5.1", smUp, t)}</>;
};

const content1_6 = (smUp, t, name) => {
  return <>{textTop("text_1.6.1", smUp, t)}</>;
};

const content2_1 = (smUp, t, name) => {
  if (name === "panel2") {
    return (
      <>
        {textTop("text_2.1.1", smUp, t)}
        <Box
          sx={{ display: "flex", justifyContent: smUp ? "start" : "center" }}
        >
          <iframe
            id="content2_1"
            className="youtube_player_shorts"
            src="https://www.youtube.com/embed/2q2Ux0eJ9NQ?si=J1vKzSchYdmMQ5Pm"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen={true}
          ></iframe>
        </Box>
      </>
    );
  } else {
    return (
      <>
        {textTop("text_2.1.1", smUp, t)}
        <Box
          sx={{ display: "flex", justifyContent: smUp ? "start" : "center" }}
        >
          <Box
            className="youtube_player_shorts"
            sx={{ backgroundColor: "#000" }}
          ></Box>
        </Box>
      </>
    );
  }
};

const content3_1 = (smUp, t, name) => {
  if (name === "panel3") {
    return (
      <>
        {textTop("text_3.1.1", smUp, t)}
        <Box
          sx={{ display: "flex", justifyContent: smUp ? "start" : "center" }}
        >
          <iframe
            id="content3_1"
            className="youtube_player_shorts"
            src="https://www.youtube.com/embed/bPWByp4_hJQ?si=b5zwwtDhC8Eg7eFA"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen={true}
          ></iframe>
        </Box>
      </>
    );
  } else {
    return (
      <>
        {textTop("text_3.1.1", smUp, t)}
        <Box
          sx={{ display: "flex", justifyContent: smUp ? "start" : "center" }}
        >
          <Box
            className="youtube_player_shorts"
            sx={{ backgroundColor: "#000" }}
          ></Box>
        </Box>
      </>
    );
  }
};

const content4_1 = (smUp, t, name) => {
  if (name === "panel4") {
    return (
      <>
        {textTop("text_4.1.1", smUp, t)}
        <ul
          style={{
            fontFamily: "Montserrat",
            fontSize: smUp ? 16 : 13,
            fontWeight: 500,
          }}
        >
          <li>{t("text_4.1.2")}</li>
          <li>{t("text_4.1.3")}</li>
          <li>{t("text_4.1.4")}</li>
          <i
            style={{
              fontFamily: "Montserrat",
              fontSize: smUp ? 16 : 13,
              fontWeight: 500,
              color: "rgba(0,0,0,0.50)",
            }}
          >
            {t("text_4.1.5")}
          </i>
          <li>{t("text_4.1.6")}</li>
          <li>{t("text_4.1.7")}</li>
          <li>{t("text_4.1.8")}</li>
          <li>{t("text_4.1.9")}</li>
          <li>{t("text_4.1.10")}</li>
          <li>{t("text_4.1.11")}</li>
        </ul>
        <Box
          sx={{ display: "flex", justifyContent: smUp ? "start" : "center" }}
        >
          <iframe
            id="content4_1"
            className="youtube_player_shorts"
            src="https://www.youtube.com/embed/A88_BqRV97U?si=pgeTuJp5wi6dmTtT"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen={true}
          ></iframe>
        </Box>
        {text("text_4.1.12", smUp, t)}
        <ul
          style={{
            fontFamily: "Montserrat",
            fontSize: smUp ? 16 : 13,
            fontWeight: 500,
          }}
        >
          <li>{t("text_4.1.13")}</li>
          <li>{t("text_4.1.14")}</li>
          <li>{t("text_4.1.15")}</li>
          <li>{t("text_4.1.16")}</li>
        </ul>
      </>
    );
  } else {
    return (
      <>
        {textTop("text_4.1.1", smUp, t)}
        <ul
          style={{
            fontFamily: "Montserrat",
            fontSize: smUp ? 16 : 13,
            fontWeight: 500,
          }}
        >
          <li>{t("text_4.1.2")}</li>
          <li>{t("text_4.1.3")}</li>
          <li>{t("text_4.1.4")}</li>
          <i
            style={{
              fontFamily: "Montserrat",
              fontSize: smUp ? 16 : 13,
              fontWeight: 500,
              color: "rgba(0,0,0,0.50)",
            }}
          >
            {t("text_4.1.5")}
          </i>
          <li>{t("text_4.1.6")}</li>
          <li>{t("text_4.1.7")}</li>
          <li>{t("text_4.1.8")}</li>
          <li>{t("text_4.1.9")}</li>
          <li>{t("text_4.1.10")}</li>
          <li>{t("text_4.1.11")}</li>
        </ul>
        <Box
          sx={{ display: "flex", justifyContent: smUp ? "start" : "center" }}
        >
          <Box
            className="youtube_player_shorts"
            sx={{ backgroundColor: "#000" }}
          ></Box>
        </Box>
        {text("text_4.1.12", smUp, t)}
        <ul
          style={{
            fontFamily: "Montserrat",
            fontSize: smUp ? 16 : 13,
            fontWeight: 500,
          }}
        >
          <li>{t("text_4.1.13")}</li>
          <li>{t("text_4.1.14")}</li>
          <li>{t("text_4.1.15")}</li>
          <li>{t("text_4.1.16")}</li>
        </ul>
      </>
    );
  }
};

const content5_1 = (smUp, t, name) => {
  if (name === "panel5") {
    return (
      <>
        {textTop("text_5.1.1", smUp, t)}
        <Box
          sx={{ display: "flex", justifyContent: smUp ? "start" : "center" }}
        >
          <iframe
            id="content5_1"
            className="youtube_player_shorts"
            src="https://www.youtube.com/embed/Z5cPdVPWbNc?si=Hm5HMieumBKFiDxD"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen={true}
          ></iframe>
        </Box>
      </>
    );
  } else {
    return (
      <>
        {textTop("text_5.1.1", smUp, t)}
        <Box
          sx={{ display: "flex", justifyContent: smUp ? "start" : "center" }}
        >
          <Box
            className="youtube_player_shorts"
            sx={{ backgroundColor: "#000" }}
          ></Box>
        </Box>
      </>
    );
  }
};

const content = [
  [content1_1, content1_2, content1_3, content1_4, content1_5, content1_6],
  [content2_1],
  [content3_1],
  [content4_1],
  [content5_1],
];

export const ContentPack = {
  title,
  content,
};
